import React from 'react';
import clsx from "clsx";
import Button from '@material-ui/core/Button';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  navigationButton: {
    minWidth: '50px',
    minHeight: '50px',
  },
  pageNavigator: {
    color: '#b4b4b4',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#e9e9e9',
      color: '#858585',
    }
  },
  stepNavigator: {
    backgroundColor: '#858585',
    color: '#fff',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#b4b4b4',
    }
  },
  selected: {
    color: '#222222',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      textDecoration: 'underline',
      textUnderlinePosition: 'under',
      color: '#222222',
    }
  },
  disabled: {
    backgroundColor: '#858585 !important',
    '& span': {
      color: '#a9a9a9',
    }
  }
});
interface PaginationProps {
  count: number;
  currentPage: number;
  className: string;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

export default function Pagination({
  count,
  currentPage,
  className,
  onChange,
}: PaginationProps) {
  const classes = useStyles();
  const { items } = usePagination({
    count,
    page: currentPage,
    onChange,
  });
  
  return (
    <nav>
      <ul className={clsx(classes.ul, className)}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <Button
                className={clsx(
                  classes.navigationButton,
                  classes.pageNavigator,
                  {
                    [classes.selected]: selected,
                  }
                )}
                type="button" 
                {...item}
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <IconButton 
                className={clsx(classes.navigationButton, classes.stepNavigator)}
                classes={{
                  disabled: classes.disabled,
                }}
                type="button" 
                {...item}
              >
                {type === 'next' && <NavigateNextIcon />}
                {type === 'previous' && <NavigateBeforeIcon />}
              </IconButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}
